import React from "react";

const NavBar = () => {
  return (
    <div className="navBar">
      <a href="http://tasks.zero9.io">Tasks</a>
      <a href="#">Cat Quest</a>
      <a href="#">FireFight</a>
    </div>
  );
};

export default NavBar;
